import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Theme } from '../../Utils/Constants';
// import { AuthModule } from '../../API';
import { useNavigate } from 'react-router-dom';
import HeaderDropDown from './HeaderDropDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import logoImg from '../../assets/images/logo.svg';
import { checkIsAdminLoggedIn, getAboutMe } from '../../API/Auth';
import useOutSideClick from '../hooks/useOutSide';
import useAppStore from '../../store/app.store';
import { fetchAdminTableData } from '../../API/AdminPageAPI';
export enum CurrentPage {
  BackTesting = 'BackTesting',
  AI_Strategies = 'AI_Strategies',
  Market_News = 'Market_News',
  Learn = 'Learn',
  Pricing = 'Pricing',
  Leaderboard = 'Leaderboard',
  None = 'None',
}

interface HeaderProps {
  currentPage: CurrentPage;
}

function Header({ currentPage }: HeaderProps) {
  const navigate = useNavigate();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  // const [profileName, setProfileName] = useState("User");
  const containerRef = useRef(null);
  const { isAuthenticated, setAdminTableData } = useAppStore();
  const { profileName } = useAppStore();
  const { setIsAuthenticated } = useAppStore();
  const { setProfileName } = useAppStore();
  useOutSideClick(containerRef, () => {
    if (isDropdownVisible) {
      setDropdownVisible(false);
    }
  });
  // useEffect(() => {
  //   const isLoggedIn = checkIsAdminLoggedIn();
  //   if (isLoggedIn) return setIsAuthenticated(true);
  // }, []);

  const apiUrl =
    (process.env.REACT_APP_BASE_URL as string) + 'strategy/strategy-list';

  useEffect(() => {
    const checkAdminStatus = async () => {
      const isLoggedIn = await checkIsAdminLoggedIn();
      if (isLoggedIn.loggedIn) {
        setIsAuthenticated(true);
        // Assuming you have access to user data here
        const userData = await getAboutMe();
        setProfileName(userData.User.first_name);
        // Assuming userData contains the user object
        // console.log("User data:", userData);
      }
    };

    const fetchData = async () => {
      try {
        fetchAdminTableData(apiUrl).then((data) => setAdminTableData(data));
      } catch (error) {
        console.error('Error fetching strategy list:', error);
      }
    };

    fetchData();
    checkAdminStatus();
  }, []);

  const signOut = useCallback(() => {
    // AuthModule.getInstance().performLogout();
    localStorage.clear();
    navigate('/', { replace: true });
  }, [navigate]);

  // useMemo(() => {
  //   AuthModule.getInstance()
  //     .getAboutMe()
  //     .then((res) => {
  //       setProfileName(res.name);
  //       setIsAuthenticated(true);
  //       return res;
  //     })
  //     .catch((err) => {
  //       console.error('error from header', err);
  //       setIsAuthenticated(false);
  //     });
  // }, []);

  function toggleDropdown() {
    setDropdownVisible(!isDropdownVisible);
  }

  function getStylesForPage(page: CurrentPage) {
    if (page === currentPage) {
      return {
        ...styles.centerTab,
        ...styles.highlightTab,
      };
    } else {
      return styles.centerTab;
    }
  }

  const loggedInDropDownMenu = () => {
    return (
      <div
        style={styles.rightContainerStyle}
        onClick={toggleDropdown}
        ref={containerRef as React.RefObject<HTMLDivElement>}
      >
        <span style={styles.profileNameStyle}>Hi, {profileName}</span>
        {isDropdownVisible ? (
          <KeyboardArrowUpIcon style={styles.buttonIcon} />
        ) : (
          <KeyboardArrowDownIcon style={styles.buttonIcon} />
        )}
        {isDropdownVisible && <HeaderDropDown onSignOut={signOut} />}
      </div>
    );
  };

  const loggedOutSignUpButton = () => {
    return (
      <div
        style={styles.rightContainerStyle}
        onClick={() => {
          navigate('/login', { replace: true });
        }}
      >
        <span style={styles.profileNameStyle}>Sign Up Free</span>
      </div>
    );
  };

  return (
    <header style={styles.headerStyle}>
      <div
        style={{ ...styles.leftContainerStyle, cursor: 'pointer' }}
        onClick={() => {
          navigate('/admin');
        }}
      >
        <img src={logoImg} alt='logo' style={styles.profileImageStyle} />
      </div>
      <div style={styles.centerContainer}>
        {/* <div
          style={getStylesForPage(CurrentPage.BackTesting)}
          onClick={() => {
            navigate('/admin');
          }}
        >
          Backtesting
        </div> */}
        <div
          style={getStylesForPage(CurrentPage.AI_Strategies)}
          onClick={() => {
            navigate('/admin');
          }}
        >
          AI Strategies
        </div>
        {/* <div style={getStylesForPage(CurrentPage.Market_News)}>Market news</div>
        <div style={getStylesForPage(CurrentPage.Learn)}>Learn</div>
        <div style={getStylesForPage(CurrentPage.Pricing)}>Pricing</div>
        <div style={getStylesForPage(CurrentPage.Leaderboard)}>Leaderboard</div> */}
      </div>
      {isAuthenticated && loggedInDropDownMenu()}
      {!isAuthenticated && loggedOutSignUpButton()}
    </header>
  );
}

const styles = {
  buttonIcon: {
    color: Theme.colors.white,
    fontSize: Theme.fontSizes.h2,
    margin: 'auto',
  },
  headerStyle: {
    backgroundColor: Theme.colors.white,
    color: '#fff',
    display: 'flex',
    borderBottom: `2px solid ${Theme.colors.whiteGrey}`,
    justifyContent: 'space-between',
  },
  centerContainer: {
    flex: 1,
    textAlign: 'left' as 'left',
    paddingLeft: Theme.gapSmall,
    marginTop: Theme.gapTiny,
    color: Theme.colors.black70,
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    fontSize: Theme.fontSizes.h4,
    flexDirection: 'row' as 'row',
  },
  leftContainerStyle: {
    textAlign: 'center' as 'center',
    margin: 'auto',
    width: Theme.navBarWidth,
  },
  centerTab: {
    display: 'block',
    cursor: 'pointer',
    fontWeight: Theme.fontWeight.semiBold,
    padding: Theme.gapSmall,
    paddingTop: Theme.gapTiny,
  },
  highlightTab: {
    backgroundColor: Theme.colors.yellow,
    borderTop: `1px solid ${Theme.colors.blueSolid}`,
    borderLeft: `1px solid ${Theme.colors.blueSolid}`,
    borderRight: `1px solid ${Theme.colors.blueSolid}`,
    color: Theme.colors.blueSolid,
  },
  rightContainerStyle: {
    textAlign: 'right' as 'right',
    display: 'flex' as 'flex',
    alignItems: 'right' as 'right',
    cursor: 'pointer',
    backgroundColor: Theme.colors.blueSolid,
    borderRadius: Theme.borderRadius,
    padding: Theme.gapTiny,
    margin: `auto ${Theme.gapSmall}`,
  },
  profileImageStyle: {
    width: Theme.logoWidthNavBar,
    paddingLeft: Theme.logoWidthNavBarGap,
    paddingRight: Theme.logoWidthNavBarGap,
  },
  profileNameStyle: {
    fontSize: Theme.fontSizes.h4,
    color: Theme.colors.white,
    paddingRight: Theme.gapSmall,
    paddingLeft: Theme.gapSmall,
  },
};

export default Header;
