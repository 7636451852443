import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { Theme } from "../../../Utils/Constants";
import {
  EditLotsAPICall,
  UserDetailsByStartegyNameApiResponse,
} from "../../../API/AdminPageAPI";
import {
  UserLiveTradeAPIInterface,
  fetchAllTradingPositionByStrategyAndUserId,
} from "./CustomerTableAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { to } from "@react-spring/web";
import CancelIcon from "@mui/icons-material/Cancel";
import { truncate } from "fs";

interface TotalStrategyPnlUser {
  userid: number;
  totalsum: number;
}

interface RowProps {
  userDetails: UserDetailsByStartegyNameApiResponse;
  index: number;
  capitalDeployed: number;
  TotalStrategyPnlForAllUsers: TotalStrategyPnlUser[];
  setAlgoPositionDetailsForAllUsers: Dispatch<
    SetStateAction<{ [userID: string]: { [key: string]: PositionDetails[] } }>
  >;
  algoPositionDetailsForAllUsers: {
    [userID: string]: {
      [key: string]: PositionDetails[];
    };
  };
}

interface LtpData {
  [position: string]: {
    last_price: number;
  };
}

export interface PositionDetails {
  PositionID: string;
  Position: string;
  Quantity: string;
  EntryAveragePrice: string | number;
  Entry: boolean;
  Direction: string;
  ExitAveragePrice: string | null;
  PnL: string | null;
  ExitOrderStatus: string | null;
  strategy_id?: string;
  LastUpdated: string;
  OrderStatus?: string;
}

const CustomTabRow: React.FC<RowProps> = ({
  userDetails,
  index,
  TotalStrategyPnlForAllUsers,
  setAlgoPositionDetailsForAllUsers,
  algoPositionDetailsForAllUsers,
}) => {
  const [isOn, setIsOn] = useState(false);
  const [isRestart, setIsRestart] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [lotValue, setLotValue] = useState("0");
  const [ltpsData, setLTPSData] = useState<LtpData>({});
  const [totalPNL, setTotalPNL] = useState<number>(0);

  const TotalStrategyPnl: number[] = [];
  const TotalStrategyLastUpdatedForAllUsers: string[] = [];

  const handleOnOff = () => {
    setIsOn((prevState) => !prevState);
  };
  const handleRestart = () => {
    setIsRestart((prevState) => !prevState);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLotValue(e.target.value);
  };

  const handleEditIconClick = () => {
    setIsEditing(true);
  };

 let capitalDeployed = 400000;
  useEffect(() => {
    setLotValue(userDetails.lots.toString());
  }, [userDetails.lots]);

  // useEffect(()=> {
  //   if(algoPositionDetailsForAllUsers){
  //     console.log(algoPositionDetailsForAllUsers);
  //   }
  // },[algoPositionDetailsForAllUsers])

  const handleEditLots = (newValue: number) => {
    EditLotsAPICall(
      userDetails.strategy_id,
      userDetails.user_id,
      newValue
    ).then((data) => {
      if (data.Status_code === 200) {
        alert("Lots updated successfully");
        // toast.success("Lots updated successfully",{
        //   autoClose: 1000,
        // });
        setLotValue(newValue.toString());
      } else {
        setLotValue(userDetails.lots.toString());
        alert(data.Message);
        // toast.error(data.Message,{
        //   autoClose: 1000,
        // });
      }
    });
  };

  const handleInputBlur = () => {
    setIsEditing(false);
    const newValue = parseInt(lotValue, 10);
    handleEditLots(newValue);
  };
  const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleInputBlur();
    }
  };
  const [algoPositionsDataByStrategiesId, setalgoPositionsDataByStrategiesId] =
    useState<{
      [userID: string]: {
        [key: string]: PositionDetails[];
      };
    }>({});
  const [isStrategyStatus, setIsStrategyStatus] = useState<string | null>(
    "Stopped"
  );

  const updateStrategyStatus = (data: any) => {
    if (data !== null && data != undefined) {
      const hasNonZeroQuantity = Object.values(data).some(
        (row: any) => row.Entry === true
      );
      const status = hasNonZeroQuantity ? "Active" : "Stopped";
      setIsStrategyStatus(status);
    } else {
      setIsStrategyStatus("Stopped");
    }
    // console.log('startegy status', isStrategyStatus);
  };

  const fetchDataForStrategy = async (
    strategyId: string,
    userId: string
  ): Promise<PositionDetails[]> => {
    try {
      const apiResponse: UserLiveTradeAPIInterface =
        await fetchAllTradingPositionByStrategyAndUserId(userId, strategyId);

      const responseDataArray: PositionDetails[] =
        apiResponse.response_data.PositionDetails || [];

      const filteredResponseDataArray = responseDataArray.filter(
        (item) => item.ExitOrderStatus !== "REJECTED"
      );

      return filteredResponseDataArray;
    } catch (error) {
      console.error(`Error fetching data for strategyId ${strategyId}:`, error);
      return []; // Return empty array to handle error case
    }
  };

  const fetchTradingPositionByStrategyId = async (strategyId: string) => {
    try {
      const data = await fetchDataForStrategy(
        strategyId,
        userDetails.user_id.toString()
      );

      if (userDetails.user_id) {
        setalgoPositionsDataByStrategiesId((prevData) => {
          const mappedPositionDataByStrategyId: {
            [userID: string]: { [key: string]: PositionDetails[] };
          } = {};

          // Check if the user_id exists in mappedPositionDataByStrategyId, if not, initialize it
          if (!mappedPositionDataByStrategyId[userDetails.user_id.toString()]) {
            mappedPositionDataByStrategyId[userDetails.user_id.toString()] = {};
          }

          // Now you can safely assign the data to the nested object
          mappedPositionDataByStrategyId[userDetails.user_id.toString()][
            strategyId
          ] = data;
          updateStrategyStatus(data);
          return { ...prevData, ...mappedPositionDataByStrategyId };
        });
        setAlgoPositionDetailsForAllUsers((prevData) => {
          const mappedPositionDataByStrategyId: {
            [userID: string]: { [key: string]: PositionDetails[] };
          } = {};

          // Check if the user_id exists in mappedPositionDataByStrategyId, if not, initialize it
          if (!mappedPositionDataByStrategyId[userDetails.user_id.toString()]) {
            mappedPositionDataByStrategyId[userDetails.user_id.toString()] = {};
          }

          // Now you can safely assign the data to the nested object
          mappedPositionDataByStrategyId[userDetails.user_id.toString()][
            strategyId
          ] = data;
          updateStrategyStatus(data);
          return { ...prevData, ...mappedPositionDataByStrategyId };
        });
      }
    } catch (error) {
      console.error(
        "Error fetching and storing data for strategy ID:",
        strategyId,
        error
      );
    }
  };

  useEffect(() => {
    fetchTradingPositionByStrategyId(userDetails?.strategy_id);
  }, []);

  const ws = useRef<WebSocket | null>(null);

  
  const APPSYNC_HOST = process.env.REACT_APP_APPSYNC_HOST as string;
  const APPSYNC_REALTIME_HOST = process.env.REACT_APP_APPSYNC_HOST_REALTIME as string;

  function encodeAppSyncCredentials() {
    const creds = {
      host: APPSYNC_HOST,
      "x-api-key": process.env.REACT_APP_APPSYNC_API_KEY as string,
    };
    const b64Creds = window.btoa(JSON.stringify(creds));
    return b64Creds;
  }

  function subscribeMessage(query: string) {
    return {
      id: window.crypto.randomUUID(),
      type: "start",
      payload: {
        data: JSON.stringify({
          query: `${query}`,
        }),
        extensions: {
          authorization: {
            "x-api-key": process.env.REACT_APP_APPSYNC_API_KEY as string,
            host: APPSYNC_HOST,
          },
        },
      },
    };
  }

  // websocket call starts

  async function getLiveLtp() {
    const header = encodeAppSyncCredentials();
    const payload = window.btoa(JSON.stringify({}));
    const url = `wss://${APPSYNC_REALTIME_HOST}/graphql?header=${header}&payload=${payload}`;

    try {
      ws.current = new WebSocket(url, ["graphql-ws"]);
      ws.current.onopen = () => {
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
          ws.current.send(JSON.stringify({ type: "connection_init" }));

          console.log("Web socket connected successfully");

          ws.current?.send(
            JSON.stringify(
              subscribeMessage(`
            subscription MySubscription {
              onCreateMoneyDevPositionLTP {
                LTP
                Position
              }
            }
            `)
            )
          );
          ws.current?.send(
            JSON.stringify(
              subscribeMessage(`
              subscription MySubscription {
                onUpdateMoneyDevPositionLTP {
                      LTP
                      Position
              }
      }
            `)
            )
          );

          ws.current?.send(
            JSON.stringify(
              subscribeMessage(`
              subscription MySubscription {
                onCreateMoneyUserOrderDetails(UserId: "${userDetails.user_id}") {
                  AveragePrice
                  AveragePriceType
                  DateTime
                  Direction
                  Entry
                  ExitAveragePrice
                  ExitReason
                  ExitSignalID
                  ExitSignalTime
                  LastUpdated
                  Lots
                  Ltp
                  OrderIDs
                  OrderStatus
                  Position
                  PositionID
                  Quantity
                  SignalID
                  Slices
                  TradingDate
                  UserId
                }
              }
              `)
            )
          );

          ws.current?.send(
            JSON.stringify(
              subscribeMessage(`
              subscription MySubscription {
                onUpdateMoneyUserOrderDetails(UserId: "${userDetails.user_id}") {
                  AveragePrice
                  AveragePriceType
                  DateTime
                  Direction
                  Entry
                  ExitAveragePrice
                  ExitReason
                  ExitSignalID
                  ExitSignalTime
                  LastUpdated
                  Lots
                  Ltp
                  OrderIDs
                  OrderStatus
                  Position
                  PositionID
                  Quantity
                  SignalID
                  Slices
                  TradingDate
                  UserId
                }
              }
              `)
            )
          );
        } else {
          console.log("WebSocket is not open or initialized properly");
        }
      };

      ws.current.onmessage = (e) => {
        // // console.log(e.data);

        const { payload } = JSON.parse(e.data);
        if (payload && payload.data) {
          const { data } = payload;

          //  when in real time LTP data is getting updated
          if (
            data &&
            Object.keys(data).length === 1 &&
            data.onUpdateMoneyDevPositionLTP
          ) {
            const { Position, LTP } = data.onUpdateMoneyDevPositionLTP;
            // // console.log('Position', Position);

            setLTPSData((prevLtpsData) => {
              const updatedData = {
                ...prevLtpsData,
                [Position]: {
                  last_price: LTP,
                },
              };
              //              // console.log('Updated LTPS data: ', updatedData);
              return updatedData;
            });
          }
          //  when in real time new positon is getting updated
          if (
            data &&
            Object.keys(data).length === 1 &&
            data.onCreateMoneyDevPositionLTP
          ) {
            const { Position, LTP } = data.onCreateMoneyDevPositionLTP;
            // console.log('Position', Position);

            setLTPSData((prevLtpsData) => {
              const updatedData = {
                ...prevLtpsData,
                [Position]: {
                  last_price: LTP,
                },
              };
              // console.log('Updated LTPS data: ', updatedData);
              return updatedData;
            });
          }

          if (
            data &&
            Object.keys(data).length === 1 &&
            data.onCreateMoneyUserOrderDetails
          ) {
            const newDeatils = data.onCreateMoneyUserOrderDetails;

            const newData = {
              Position: newDeatils.Position,
              EntryAveragePrice: newDeatils.AveragePrice,
              Quantity: newDeatils.Quantity,
              ExitAveragePrice: newDeatils.ExitAveragePrice,
              Entry: newDeatils.Entry,
              PositionID: newDeatils.PositionID,
              Direction: newDeatils.Direction,
              PnL: null,
              lastUpdated: newDeatils.LastUpdated,
              OrderStatus: newDeatils.OrderStatus,
            } as unknown as PositionDetails;

            setalgoPositionsDataByStrategiesId((prevData) => {
              const strategyID = userDetails.strategy_id.toString();
              const userID = userDetails.user_id.toString();

              // Create a copy of the previous data to avoid direct mutation
              const updatedData = { ...prevData };

              // Initialize userID in updatedData if it doesn't exist
              if (!updatedData[userID]) {
                updatedData[userID] = {};
              }

              // Initialize strategyID in updatedData[userID] if it doesn't exist
              if (!Array.isArray(updatedData[userID][strategyID])) {
                updatedData[userID][strategyID] = [];
              }

              // Add the new data to the appropriate strategyID array
              updatedData[userID][strategyID] = [
                ...updatedData[userID][strategyID],
                newData,
              ];

              // Call updateStrategyStatus with the user's updated data
              updateStrategyStatus(updatedData[userID]);

              console.log(updatedData);

              return updatedData;
            });

            setAlgoPositionDetailsForAllUsers((prevData) => {
              const strategyID = userDetails.strategy_id.toString();
              const userID = userDetails.user_id.toString();

              // Create a copy of the previous data to avoid direct mutation
              const updatedData = { ...prevData };

              // Initialize userID in updatedData if it doesn't exist
              if (!updatedData[userID]) {
                updatedData[userID] = {};
              }

              // Initialize strategyID in updatedData[userID] if it doesn't exist
              if (!Array.isArray(updatedData[userID][strategyID])) {
                updatedData[userID][strategyID] = [];
              }

              // Add the new data to the appropriate strategyID array
              updatedData[userID][strategyID] = [
                ...updatedData[userID][strategyID],
                newData,
              ];

              // Call updateStrategyStatus with the user's updated data
              updateStrategyStatus(updatedData[userID]);

              console.log(updatedData);

              return updatedData;
            });

            setalgoPositionsDataByStrategiesId((prevData) => {
              const mappedPositionDataByStrategyId: {
                [userID: string]: { [key: string]: PositionDetails[] };
              } = {};

              // Check if the user_id exists in mappedPositionDataByStrategyId, if not, initialize it
              if (
                !mappedPositionDataByStrategyId[userDetails.user_id.toString()]
              ) {
                mappedPositionDataByStrategyId[userDetails.user_id.toString()] =
                  {};
              }

              // Now you can safely assign the data to the nested object
              mappedPositionDataByStrategyId[userDetails.user_id.toString()][
                userDetails.strategy_id
              ] = data;

              updateStrategyStatus(data);
              return { ...prevData, ...mappedPositionDataByStrategyId };
            });
          }

          if (
            data &&
            Object.keys(data).length === 1 &&
            data.onUpdateMoneyUserOrderDetails
          ) {
            const newDeatils = data.onUpdateMoneyUserOrderDetails;

            // Assuming algoPositionsDataByStrategiesId contains your current state data
            setalgoPositionsDataByStrategiesId((prevData) => {
              const userID = userDetails.user_id.toString();
              const strategyID = userDetails.strategy_id.toString();

              // Ensure the user and strategy data exist in the previous data
              if (
                prevData[userID] &&
                Array.isArray(prevData[userID][strategyID])
              ) {
                const updatedStrategyData = prevData[userID][strategyID].map(
                  (item) => {
                    if (item.PositionID === newDeatils.PositionID) {
                      // Update the item with the new details
                      return {
                        ...item,
                        ExitAveragePrice: newDeatils.ExitAveragePrice,
                        Entry: newDeatils.Entry,
                      };
                    }
                    return item;
                  }
                );

                // Create a copy of the previous data
                const updatedData = { ...prevData };

                // Update the specific strategy data for the user
                updatedData[userID] = {
                  ...prevData[userID],
                  [strategyID]: updatedStrategyData,
                };

                // Update the strategy status with the updated data
                updateStrategyStatus(updatedData[userID][strategyID]);

                console.log(updatedData);

                return updatedData;
              } else {
                // If userID or strategyID does not exist, return the previous state unchanged
                console.warn(
                  `User ID ${userID} or Strategy ID ${strategyID} not found in previous data.`
                );
                return prevData;
              }
            });

            setAlgoPositionDetailsForAllUsers((prevData) => {
              const userID = userDetails.user_id.toString();
              const strategyID = userDetails.strategy_id.toString();

              // Ensure the user and strategy data exist in the previous data
              if (
                prevData[userID] &&
                Array.isArray(prevData[userID][strategyID])
              ) {
                const updatedStrategyData = prevData[userID][strategyID].map(
                  (item) => {
                    if (item.PositionID === newDeatils.PositionID) {
                      // Update the item with the new details
                      return {
                        ...item,
                        ExitAveragePrice: newDeatils.ExitAveragePrice,
                        Entry: newDeatils.Entry,
                      };
                    }
                    return item;
                  }
                );

                // Create a copy of the previous data
                const updatedData = { ...prevData };

                // Update the specific strategy data for the user
                updatedData[userID] = {
                  ...prevData[userID],
                  [strategyID]: updatedStrategyData,
                };

                // Update the strategy status with the updated data
                updateStrategyStatus(updatedData[userID][strategyID]);

                console.log(updatedData);

                return updatedData;
              } else {
                // If userID or strategyID does not exist, return the previous state unchanged
                console.warn(
                  `User ID ${userID} or Strategy ID ${strategyID} not found in previous data.`
                );
                return prevData;
              }
            });
          }
        }
      };

      ws.current.onerror = (e) => {
        console.log("Some error occured", e);
      };
      ws.current.onclose = (e) => {
        console.log(e.code, e.reason);
        console.log("Socket is closed. Reconnect will be attempted in 1 second.");
        // setTimeout(() => {
        //   getLiveLtp();
        // }, 1000);
      };
    } catch (error) {
      console.log("An error has occurred");
    }
  }

  // websocket call end

  useEffect(() => {
    getLiveLtp();
  }, []);

  const getLtpForPosition = (positionValue: string): number | undefined => {
    const posValue = `${positionValue}`;

    if (ltpsData[posValue]) {
      return ltpsData[posValue]?.last_price;
    } else {
      // console.log(`Position ${posValue} not found in the data`);
      return undefined;
    }
  };

  const handlePnlPriceWhenPositonisOpen = (
    ltpPrice: number,
    entryPrice: number,
    direction: string,
    quantity: number
  ) => {
    let res = 0;
    if (direction === "BUY") {
      res = ltpPrice - entryPrice;
    } else if (direction === "SELL") {
      res = entryPrice - ltpPrice;
    }
    let _LegPnl;
    _LegPnl = res * quantity;
    TotalStrategyPnl.push(_LegPnl);
    return _LegPnl;
  };

  const handlePnlPriceWhenPositonisClosed = (
    entryPrice: number,
    direction: string,
    quantity: number,
    exitPrice: number
  ) => {
    let res = 0;
    if (direction === "BUY") {
      res = exitPrice - entryPrice;
    } else if (direction === "SELL") {
      res = entryPrice - exitPrice;
    }
    let _LegPnl;
    _LegPnl = res * quantity;
    TotalStrategyPnl.push(_LegPnl);
    return _LegPnl;
  };

  const TotalStrategyPnlbyStrategyID = () => {
    // console.log(TotalStrategyPnl, 'TotalStrategyPnl', userDetails.user_id);

    const totalSum: number = TotalStrategyPnl.reduce(
      (acc, currentValue) => acc + currentValue,
      0
    );


    // console.log(capitalDeployed, userDetails.name, 'capitalDeployed', typeof capitalDeployed);
    if (capitalDeployed !== undefined) {
      capitalDeployed = parseFloat(
        capitalDeployed.toString().replace(/,/g, "")
      );
    }
    let totalSumPercentage = 0;
    if (capitalDeployed === undefined || capitalDeployed == 0) {
      totalSumPercentage = 0;
    } else if (capitalDeployed !== 0) {
      totalSumPercentage =
        (totalSum / (capitalDeployed * userDetails.lots)) * 100;
    }

    
    const existingEntry = TotalStrategyPnlForAllUsers.find(
      (entry) => entry.userid === userDetails.user_id
    );

    if (existingEntry) {
      existingEntry.totalsum = totalSumPercentage;
    } else {
      TotalStrategyPnlForAllUsers.push({
        userid: userDetails.user_id,
        totalsum: totalSumPercentage,
      });
    }
    return totalSumPercentage;
  };

  const VarianceInStrategyPnlbyStrategyID = () => {
    if (TotalStrategyPnlForAllUsers.length === 0) {
      return 0;
    }

    const max: number = TotalStrategyPnlForAllUsers.reduce((max, entry) => {
      return entry.totalsum > max ? entry.totalsum : max;
    }, TotalStrategyPnlForAllUsers[0].totalsum);

    /*TotalStrategyPnlForAllUsers.reduce(
      (acc, currentValue) => acc + currentValue,
      0
    );*/

    let min: number = TotalStrategyPnlbyStrategyID();
  
    const variance = max - min;
    return variance;
  };

  const handleKillSwitch = () => {
    // console.log('Kill switch clicked', userDetails.user_id, userDetails.strategy_id);
    const killSwitchData = {
      user_id: userDetails.user_id,
      strategyId: userDetails.strategy_id,
    };
    const apiURL =
      (process.env.REACT_APP_BASE_URL as string) + "admin/userKillSwitch";
    fetch(apiURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(killSwitchData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.Status_code === 200) {
          alert(data.message);
          // toast.success(data.Message, {
          //   autoClose: 1000,
          // });
        } else {
          alert(data.message);
          // toast.error(data.Message, {
          //   autoClose: 1000,
          // });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  // useEffect(() => {
  //   console.log(algoPositionsDataByStrategiesId);
  // }, [algoPositionsDataByStrategiesId]);

  let totalPositionEntryTrue = 0;
  let totalPositionEntryFalse = 0;

  let latestLastUpdated: any;
  let latestLastUpdatedStatus: any;

  useEffect(() => {
    let pnlSum = 0;
    const userPositions =
      algoPositionsDataByStrategiesId[userDetails.user_id.toString()];
    if (
      userPositions &&
      Array.isArray(userPositions[userDetails.strategy_id])
    ) {
      const positions = userPositions[
        userDetails.strategy_id
      ] as PositionDetails[];
      positions.forEach((position) => {
        if (
          position.ExitAveragePrice !== null &&
          position.EntryAveragePrice !== null
        ) {
          const pnl =
            (Number(position.ExitAveragePrice) -
              Number(position.EntryAveragePrice)) *
            Number(position.Quantity);
          pnlSum += pnl;
        }
      });
    }
    setTotalPNL(pnlSum);
  }, [algoPositionsDataByStrategiesId, userDetails]);

  interface TimeDifference {
    seconds: number;
    minutes: number;
    hours: number;
  }

  const getTimeDifference = (
    timestamps: string[],
    latest: any
  ): TimeDifference => {
    if (latest) {
      const dateObjects = timestamps.map((timestamp) => new Date(timestamp));
      const latestDate = new Date(
        Math.max(...dateObjects.map((date) => date.getTime()))
      );

      const differenceInMilliseconds = latestDate.getTime() - latest.getTime();

      // Convert the difference to seconds, minutes, and hours
      const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
      const differenceInMinutes = Math.floor(differenceInSeconds / 60);
      const differenceInHours = Math.floor(differenceInMinutes / 60);

      return {
        seconds: differenceInSeconds,
        minutes: differenceInMinutes,
        hours: differenceInHours,
      };
    } else {
      return {
        seconds: 0,
        minutes: 0,
        hours: 0,
      };
    }
  };

  interface TimeStampProps {
    latest: any;
  }

  const LatestTimestampComponent: React.FC<TimeStampProps> = ({ latest }) => {
    const { seconds, minutes, hours } = getTimeDifference(
      TotalStrategyLastUpdatedForAllUsers,
      latest
    );

    return (
      <td style={{}}>{`${hours}h :${minutes % 60}m :${seconds % 60}s`}</td>
    );
  };

  return (
    //want to add toastcontainer
    <>
      <ToastContainer />
      <tr key={index + 1} className="" style={styles.tableRow}>
        <td className="" style={{ ...styles.tableData, textAlign: "center" }}>
          {/* {index + 1} */}
          {userDetails.name}
        </td>
        <td className=" " style={{}}>
          <div
            style={{}}
            className="kill-switch-toggle-btn"
            onClick={handleKillSwitch}
          >
            {/* <div
              style={{ width: "40px", height: "17px" }}
              // className={`toggle-container ${isOn ? 'active' : ''}`}
              // onClick={handleOnOff}
              className={`toggle-container ${userDetails.is_active ? "active" : ""
                }`}
              onClick={handleOnOff}
            >
              <div
                style={{ width: "20px", height: "20px", top: "-1px" }}
                className={`slider ${isOn ? "active" : ""}`}
              />
            </div> */}
            <div className="flex flex-center" style={styles.killBtnContainer}>
              <CancelIcon
                style={{
                  color: "red",
                  // margin: '0 -3px 0 -5px',
                  padding: "0",
                }}
              />
            </div>
          </div>
        </td>
        {/* <td className=''>
      <div className='kill-switch-toggle-btn'>
        <div
          style={{ width: '40px', height: '17px' }}
          className={`toggle-container ${isRestart ? 'active' : ''}`}
          onClick={handleRestart}
        >
          <div
            style={{ width: '20px', height: '20px', top: '-1px' }}
            className={`slider ${isRestart ? 'active' : ''}`}
          />
        </div>
      </div>
    </td> */}
        <td
          style={{
            textAlign: "left",
            color:
              isStrategyStatus === "Active"
                ? Theme.colors.green
                : Theme.colors.red,
          }}
        >
          {isStrategyStatus}
        </td>
        <td
          style={{
            marginTop: "10%",
            padding: "0 5px 0 10px",
          }}
          className="flex flex-center"
        >
          {" "}
          {isEditing ? (
            <span
              className="flex flex-center"
              style={{
                border: `solid ${Theme.colors.whitishGrey}`,
                justifyContent: "space-between",
                width: "90%",
              }}
            >
              <input
                type="number"
                value={lotValue}
                inputMode="numeric"
                onChange={handleInputChange}
                // onBlur={handleInputBlur}
                // onKeyPress={handleInputKeyPress}
                autoFocus
                style={{
                  width: "70%",
                  paddingLeft: "5px",
                  border: "none",
                }}
              />
              <DoneOutlinedIcon onClick={handleInputBlur} />
            </span>
          ) : (
            <span
              className="flex flex-center"
              style={{
                border: `solid ${Theme.colors.whiteGrey30}`,
                justifyContent: "space-between",
                width: "90%",
                height: "100%",
              }}
            >
              <span style={{ paddingLeft: "5px" }}>{lotValue}</span>
              <EditOutlinedIcon onClick={handleEditIconClick} />
            </span>
          )}
          &nbsp;
        </td>
        <td
          className=""
          style={{
            textAlign: "center",
            // color:
            //   Number(data.strategy1) > 0 ? ' #2ACD1C' : '#F82929',
          }}
        >
          {algoPositionsDataByStrategiesId[userDetails.user_id.toString()] &&
            Array.isArray(
              algoPositionsDataByStrategiesId[userDetails.user_id.toString()][
              userDetails.strategy_id
              ]
            ) ? (
            <>
              {algoPositionsDataByStrategiesId[userDetails.user_id.toString()][
                userDetails.strategy_id
              ].map((position: PositionDetails, index: number) => {
                if (position.Entry === true) {
                  totalPositionEntryTrue += 1;
                }
                return null;
              })}
              <div>{totalPositionEntryTrue}</div>
            </>
          ) : (
            "0"
          )}
        </td>
        <td style={{}}>
          {algoPositionsDataByStrategiesId[userDetails.user_id.toString()] &&
            Array.isArray(
              algoPositionsDataByStrategiesId[userDetails.user_id.toString()][
              userDetails.strategy_id
              ]
            ) ? (
            <>
              {algoPositionsDataByStrategiesId[userDetails.user_id.toString()][
                userDetails.strategy_id
              ].map((position: PositionDetails, index: number) => {
                if (position.Entry === false) {
                  totalPositionEntryFalse += 1;
                }
                return null;
              })}
              <div>{totalPositionEntryFalse}</div>
            </>
          ) : (
            "0"
          )}
        </td>
        <td>
          {algoPositionsDataByStrategiesId[userDetails.user_id.toString()] &&
            Array.isArray(
              algoPositionsDataByStrategiesId[userDetails.user_id.toString()][
              userDetails.strategy_id
              ]
            ) ? (
            <>
              {algoPositionsDataByStrategiesId[userDetails.user_id.toString()][
                userDetails.strategy_id
              ].map((rowDataArray: PositionDetails, index: number) => {
                const positionValue = rowDataArray.Position;
                // const lastPrice = 0
                const lastPrice = getLtpForPosition(positionValue);

                let LegPnlprice = 0;
                if (rowDataArray.Entry) {
                  LegPnlprice = handlePnlPriceWhenPositonisOpen(
                    lastPrice || 0,
                    Number(rowDataArray.EntryAveragePrice),
                    rowDataArray.Direction,
                    Number(rowDataArray.Quantity)
                  );
                  // pnlprice = 0
                } else {
                  // pnlprice = Number(rowDataArray.PnL);
                  LegPnlprice = handlePnlPriceWhenPositonisClosed(
                    Number(rowDataArray.EntryAveragePrice),
                    rowDataArray.Direction,
                    Number(rowDataArray.Quantity),
                    Number(rowDataArray.ExitAveragePrice) || 0
                  );
                }
                return null;
              })}
              {algoPositionsDataByStrategiesId[
                userDetails.user_id.toString()
              ] ? (
                <div
                  style={
                    TotalStrategyPnlbyStrategyID() < 0
                      ? { color: Theme.colors.red }
                      : { color: Theme.colors.green }
                  }
                >
                  {TotalStrategyPnlbyStrategyID().toFixed(2) + " %"}
                </div>
              ) : (
                <div>0</div>
              )}
            </>
          ) : (
            "0.0"
          )}
        </td>

        <td style={{}}>
          {algoPositionsDataByStrategiesId[userDetails.user_id.toString()] &&
            Array.isArray(
              algoPositionsDataByStrategiesId[userDetails.user_id.toString()][
              userDetails.strategy_id
              ]
            ) &&
            algoPositionsDataByStrategiesId[userDetails.user_id.toString()][
              userDetails.strategy_id
            ].map((positonDetails: PositionDetails) => {
              const currentLastUpdated = new Date(positonDetails.LastUpdated);
              if (positonDetails.LastUpdated !== null) {
                if (
                  !latestLastUpdated ||
                  currentLastUpdated > latestLastUpdated
                ) {
                  latestLastUpdated = currentLastUpdated;
                }
              }

              {
                if (latestLastUpdated === currentLastUpdated) {
                  latestLastUpdatedStatus =
                    positonDetails.OrderStatus?.toString();
                }
              }
              return null;
            })}

          {latestLastUpdated
            ? latestLastUpdated.getHours().toString().padStart(2, "0") +
            ":" +
            latestLastUpdated.getMinutes().toString().padStart(2, "0") +
            ":" +
            latestLastUpdated.getSeconds().toString().padStart(2, "0")
            : ""}
        </td>

        <td style={{ color: Theme.colors.green }}>
          {" "}
          {latestLastUpdatedStatus && (
            <>
              <span
                style={{
                  border: "solid",
                  borderRadius: "50%",
                  margin: "auto",
                  background: Theme.colors.green,
                  color: Theme.colors.white,
                }}
              >
                &#10003;
              </span>{" "}
              {latestLastUpdatedStatus}
            </>
          )}
        </td>
        <td style={{}}>
          {latestLastUpdatedStatus === "APPROVED"
            ? "No"
            : latestLastUpdatedStatus === "REJECTED"
              ? "Yes"
              : ""}
        </td>
        {/* <td style={{}}>Yes</td> */}
        <td>
          {algoPositionsDataByStrategiesId[userDetails.user_id.toString()] &&
            Array.isArray(
              algoPositionsDataByStrategiesId[userDetails.user_id.toString()][
              userDetails.strategy_id
              ]
            ) ? (
            <>
              {Object.values(algoPositionDetailsForAllUsers).forEach(
                (value) => {
                  if (value[userDetails.strategy_id.toString()].length !== 0) {
                    value[userDetails.strategy_id.toString()].map(
                      (rowDataArray) => {
                        if (rowDataArray.LastUpdated) {
                          TotalStrategyLastUpdatedForAllUsers.push(
                            rowDataArray.LastUpdated
                          );
                        }
                      }
                    );
                  }
                }
              )}
              {algoPositionsDataByStrategiesId[
                userDetails.user_id.toString()
              ] ? (
                <div
                  style={
                    VarianceInStrategyPnlbyStrategyID() < 0
                      ? { color: Theme.colors.red }
                      : { color: Theme.colors.green }
                  }
                >
                  {VarianceInStrategyPnlbyStrategyID().toFixed(2) + " %"}
                </div>
              ) : (
                <div>0</div>
              )}
            </>
          ) : (
            "0.0"
          )}
        </td>
        {/* <td style={{}}>
          {
            TotalStrategyLastUpdatedForAllUsers[0]
          }</td> */}
        <LatestTimestampComponent latest={latestLastUpdated} />
      </tr>
    </>
  );
};

const styles = {
  tableRow: {
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.7)",
    lineHeight: "26px",
    fontSize: "16px",
  },
  tableData: {
    textAlign: "center",
    paddingTop: "13px",
    paddingBottom: "13px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "0.96px",
  },
  killBtnContainer: {
    border: `solid 1px ${Theme.greyColor}`,
    padding: "2%",
    borderRadius: "20px",
    cursor: "pointer",
  },
};

export default CustomTabRow;
